<template>
  <div class="home">
    <div class="op-header">
      <div class="header-title">
        <div class="logo-left"><i class="iconfont">&#xe617;</i></div>
        <div class="text-right">
          <span class="te-right"><a href="#topnew">首页</a></span>
          <span class="te-right"><a href="#experts">专家</a></span>
          <span><a href="#zi">联系我们</a></span>
        </div>
      </div>
    </div>
    <div class="header" id="topnew">
      <!-- <img src="../assets/header.png" alt="" /> -->
      <newHeader />
    </div>
    <div class="Introduction">
      <div class="Introduction-in">
        <img class="img-one" src="../assets/main.png" alt=""/>
        <img class="img-two" src="../assets/IgoPnga.png" alt="" />
        <div class="Introduction-text">
          <div>
            <span>&nbsp;&nbsp;根据WHO和中国卫健委的世界/中国青少年发育数据显示，国内</span><br>
            <span>青少年性早熟导致的身高负增长现象日趋严重，首都儿童保健中心做</span><br>
            <span> &nbsp;&nbsp;了一项调研来了解中国家长期望的孩子成年身高，普遍为男孩</span><br>
            <span>179cm，女孩165cm，中国的3亿儿童中有2亿是达不到这个水平的</span><br>
            <span>（其中矮小症患者10005）</span><br><br>
            <span> &nbsp;&nbsp;我们希望提供从骨龄筛查、精准评价、科学管理的全生态服务来</span><br>
            <span> 帮助更多孩子达到他们的理想身高，为孩子提供真正的私人定制生长</span><br>
            <span> 发育解决方案，为孩子成长保驾护航。</span>
          </div>
        </div>
      </div>
    </div>
    <div class="strategic" id="strategic">
      <div class="strategic-in">
        <img src="../assets/cheng.png" style="width:297px;height:99px;" alt="" /><br>
        <img style="margin-top:58px;" src="../assets/addLogo.png" alt="" />
      </div>
    </div>
    <div style="height:960px;"></div>
    <div class="founder">
      <div class="founder-in">
        <img src="../assets/Ceo.png" style="width:464px;height:643px;" alt="" />
        <div class="say">
          <img src="../assets/Mrsay.png" alt="" />
          <div class="say-text">
            <!-- <p>我们也在不断评估并改进我们的课程、器械、视频资料和指导手</p>
            <p>册，使其符合现代运动科学的原则、满足当今健身专业人士及终端用</p>
            <p>户的需求。成效：正是凭借这样的不懈努力，我们打造的课程和产品</p>
            <p>不仅独具创意、功能多样、行之有效，而且还广泛涵盖各种健身形</p>
            <p>式，能够满足各类人群的健身需求。</p>
            <p>我们也在不断评估并改进我们的课程、器械、视频资料和指导手</p>
            <p>册，使其符合现代运动科学的原则、满足当今健身专业人士及终端用</p>
            <p>户的需求。成效：正是凭借这样的不懈努力，我们打造的课程和产品</p>
            <p>不仅独具创意、功能多样、行之有效，而且还广泛涵盖各种健身形</p>
            <p>式，能够满足各类人群的健身需求。</p> -->
            <p>我们整合了各个领域里的知名专家，达成体系共识，建立了一套标准的分诊逻辑；对儿童生长发育过程中涉及到的生长发育检测、评估、及管理干预各个环节分别进行了技术整合及标准化创新，利用移动硬件+大数据制定了高的企业标准，并在此基础上研发了独家的多维度生长发育评价系统;
            <p>专注于解决青少年生长发育问题，为每个孩子建立一个可监测的成长发育数据档案，可视化的线上动态数据监测服务，能及时发现儿童生长发育过程中的各类问题，结合高特色专家会诊服务和科学的分诊逻辑提供给用户最佳的解决方案，极大降低了用户的选择成本和管理成本；</p>
            <p>以线下健康医疗体验中心和连锁门诊作为服务载体，以中西医相结合的诊疗方式，提供给用户多元化的服务体验，实现了生长发育线上线下全产业闭环，让每个孩子都能健康成长，让长高变的更简单</p>
            <!-- 
              我们整合了各个领域里的知名专家，达成体系共识，建立了一套标准的分诊逻辑；对儿童生长发育过程中涉及到的生长发育检测、评估、及管理干预各个环节分别进行了技术整合及标准化创新，利用移动硬件+大数据制定了高的企业标准，并在此基础上研发了独家的多维度生长发育评价系统;
       专注于解决青少年生长发育问题，为每个孩子建立一个可监测的成长发育数据档案，可视化的线上动态数据监测服务，能及时发现儿童生长发育过程中的各类问题，结合高特色专家会诊服务和科学的分诊逻辑提供给用户最佳的解决方案，极大降低了用户的选择成本和管理成本；
       以线下健康医疗体验中心和连锁门诊作为服务载体，以中西医相结合的诊疗方式，提供给用户多元化的服务体验，实现了生长发育线上线下全产业闭环，让每个孩子都能健康成长，让长高变的更简单
             -->
          </div>
        </div>
      </div>
    </div>
    <div class="experts" id="experts">
      <img class="expertsImg" src="../assets/experts.png" alt="" />
      <div class="Swiper-News">
        <newswiper/>
      </div>
    </div>
    <div class="baiduEcharts">
      <Baidu />
    </div>
    <div class="foot">
      <div class="foot-main">
        <div class="foot-left" id="zi">
          <p class="bot"><i class="iconfont" style="color:#ffffff;">&#xe617;</i></p><br>
          <!-- <p class="phone-name">客服电话</p> -->
          <p class="adress">浙江康体汇科技有限公司</p>
          <p class="adress">Zhejiang youths Co., Ltd.</p>
          <p class="adress" style="margin-top:12px;">地址：杭州市萧山区杭州湾信息港D座4楼401</p>
          <!-- <p class="email">邮箱：jhswkj@jhhr.com</p> -->
        </div>
        <div class="foot-right">
          <div class="company">
            <ul>
              <li>
                <p>公司信息</p>
              </li>
              <li>
                <a>关于我们></a>
              </li>
              <li>
                <a>加入我们></a>
              </li>
            </ul>
          </div>
          <div class="service">
            <ul>
              <li>
                <p>咨询和服务</p>
              </li>
              <li>
                <a href="#strategic">战略布局></a>
              </li>
              <li>
                <a href="#experts">专家团队></a>
              </li>
            </ul>
          </div>
          <div class="code">
            <img src="../assets/esolid2.jpg" alt="" /><br>
            <p>扫码快速咨询</p>
          </div>
          <div class="code">
            <img src="../assets/esolid1.jpg" alt="" /><br>
            <p>关注我们</p>
          </div>
          <div class="foot-phone">
            <p class="phone-name">联系我们</p>
            <p class="phone-ft">客服电话：0571-6666666</p>
            <p class="phone-ft">商务合作：jhswkj@jhbd.com</p>
            <p class="phone-ft">媒体合作：jhswkj@jhpr.com</p>
            <p class="phone-ft">简历投递：jhswkj@jhhr.com</p>
          </div>
        </div>
      </div>
      <div class="foot-web">
        
        <p>Copyright © 2020 浙江康体汇科技有限公司 All Right Reserved <a href="https://beian.miit.gov.cn">浙ICP备17025308号-1</a></p>
        <!-- <p>Copyright © 2020 浙江康体汇科技有限公司 All Right Reserved <a href="http://www.beian.gov.cn/portal/registerSystemInfo">浙ICP备18051765号-2</a></p> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Baidu from '@/components/baidu.vue'
import newswiper from '@/components/swiper.vue'
import newHeader from '@/components/swiperHeader.vue'

export default {
  name: 'Home',
  components: {
    Baidu,
    newswiper,
    newHeader
  }
}
</script>
<style scoped lang="less">
.home{
  width: 100%;
}
.header{
  width: 100%;
  // height: 900px;
  img{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    opacity: 1;
  }
}
.op-header{
  width: 100%;
  height: 80px;
  background: rgba(0, 0, 0, 0.2);
  // opacity: 0.2;
  position: fixed;
  z-index: 999;
  top: 0;
}
.header-title{
  width: 1200px;
  height: 80px;
  line-height: 80px;
  color: #ffffff;
  margin: auto;
  .logo-left{
    float: left;
    i{
      margin-top: 12px;
      width: 85px;
    }
  }
  .text-right{
    float: right;
    .te-right{
      padding-right: 65px;
    }
    span{
      a{
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
}
.Introduction{
  width: 100%;
  height: 611px;
  background-color: #E9F7F6;
  margin-top: -8px;
  .Introduction-in{
    width: 1200px;
    height: 100%;
    // line-height: 611px;
    // background-color: red;
    margin: auto;
    .img-one{
      width: 700px;
      // height: 467px;
      // display: inline-block;
      padding: 72px 0px;
    }
    .img-two{
      width: 554px;
      // height: 102px;
      position: relative;
      float: right;
      bottom: 522px;
      z-index: 2;
      // display: inline-block;
    }
    .Introduction-text{
      width: 680px;
      height: 300px;
      background: #27AAB7;
      opacity: 1;
      position: relative;
      float: right;
      bottom: 550px;
      z-index: 1;
      line-height: 25px;
      div{
        // width: 100%;
        padding: 31px 68px;
        text-align: center;
        span{
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: normal;
          color: #E9F7F6;
        }
      }
    }
  }
}
.strategic{
  width: 100%;
  height: 921px;
  background:#FFFFFF;
  position: absolute;
  // margin: auto;
  .strategic-in{
    width: 1200px;
    height: 100%;
    margin: auto;
    text-align: center;
    // background: chartreuse;
      img{
         margin-top: 103px;
      }
  }
}
.founder{
  width: 100%;
  height: 720px;
  margin: auto;
  background: #E9F7F6;
  .founder-in{
    width: 1200px;
    margin: auto;
    height: 100%;
    // border: 1px solid rebeccapurple;
    img{
      float: left;
      margin-top: 100px;
    }
    .say{
      // display: inline-block;
      float: left;
      margin-left: 132px;
      img{
        margin-top: 192px!important;
      }
      .say-text{
        margin-top: 304px;
        width: 554px;
        height: 286px;
        p{
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: normal;
          line-height: 25px;
          color: #043945;
          opacity: 1;
        }
      }
    }
  }
}
.experts{
  width: 100%;
  height: 960px;
  .expertsImg{
    margin-top: 64px;
  }
}
.baiduEcharts{
  height: 480px;
}
.foot{
  height: 308px;
  background: #27AAB7;
  .foot-main{
    width: 1200px;
    height: 248px;
    margin: auto;
    .foot-left{
      width: 300px;
      height: 100%;
      // padding-top: 62px;
      float: left;
      // border: 1px solid red;
      .bot{
        margin-top: 50px;
      }
      p{
        margin-top: 4px;
      }
      .phone-name{
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: normal;
        margin-top: 81px;
        // line-height: 20px;
        color: #C7EBE9;
        opacity: 1;
      }
      .phone{
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 800;
        // line-height: 33px;
        color: #FFFFFF;
        opacity: 1;
      }
      .adress{
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: normal;
        // line-height: 20px;
        color: #C7EBE9;
        opacity: 1;
      }
      .email{
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: normal;
        // line-height: 20px;
        color: #C7EBE9;
        opacity: 1;
      }
    }
    .foot-right{
      width: 898px;
      display: inline-block;
      // float: right;
      height: 100%;
      p{
        margin-top: 4px;
      }
      // border: 1px solid red;
      div{
        float: right;
        margin-right: 70px;
        margin-top: 50px;
      }
      ul>li>p{
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: normal;
        line-height: 20px;
        color: #FFFFFF;
        opacity: 1;
      }
      ul>li>a{
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: normal;
        line-height: 20px;
        color: #FFFFFF;
        opacity: 1;
      }
      .code{
        width: 84px;
        text-align: center;
        p{
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: normal;
          line-height: 20px;
          color: #FFFFFF;
          opacity: 1;
        }
        img{
          width: 80px;
        }
      }
      .foot-phone{
        .phone-name{          
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 20px;
          color: #FFFFFF;
          opacity: 1;
        }
        .phone-ft{    
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 20px;
          color: #C7EBE9;
          opacity: 1;
        }
      }
    }
    // border: 1px solid green;
  }
  .foot-web{
    height: 60px;
    // border: 1px solid blue;
    background: #49BEC4;
    line-height: 60px;
    p{
      text-align: center;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: normal;
      color: #E2F3F2;
      opacity: 1;
      a{
        color: #E2F3F2;
      }
    }
  }
}
.Swiper-News{
  width: 1200px;
  margin: auto;
  margin-top: 60px;
  height: 700px;
  // border: 1px solid red;
}
.service{
  a{
    text-decoration: none;
    margin-top: 6px;
  }
}
.iconfont{
    font-family:"iconfont" !important;
    font-size:60px;
    font-style:normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0px;
    -moz-osx-font-smoothing: grayscale;}
</style>
