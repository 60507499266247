<template>
  <div class="main">
    <div class="swiper-container">
          <div class="swiper-wrapper">
              <div class="swiper-slide swiper-no-swiping"><img src="../assets/DQ.png" alt="" /></div>
              <div class="swiper-slide swiper-no-swiping"><img src="../assets/JJX.png" alt="" /></div>
              <div class="swiper-slide swiper-no-swiping"><img src="../assets/LB.png" alt="" /></div>
              <div class="swiper-slide swiper-no-swiping"><img src="../assets/LGY.png" alt="" /></div>
              <div class="swiper-slide swiper-no-swiping"><img src="../assets/YWB.png" alt="" /></div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>
          <!-- 如果需要导航按钮 -->
          <!-- <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div> -->
          <!-- 如果需要滚动条 -->
          <!-- <div class="swiper-scrollbar"></div> -->
      </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
export default {
  name: 'swiper',
  props: {
    msg: String
  },
  data() {
    return {
    }
  },
  mounted(){
    new Swiper ('.swiper-container', {
        autoplay:3000,//自动滑动
        speed:500,//自动滑动开始到结束的时间（单位ms）
        loop:true,//开启循环
        loopedSlides:5,//在loop模式下使用slidesPerview:'auto',还需使用该参数设置所要用到的loop个数。
        slidesPerView:'auto',//设置slider容器能够同时显示的slides数量(carousel模式)。另外，支持'auto'值，会根据容器container的宽度调整slides数目。
        effect:'coverflow',//可以实现3D效果的轮播,
        pagination:'.swiper-pagination',//分页器
        centeredSlides:true,//设定为true时，active slide会居中，而不是默认状态下的居左。
        coverflow:{
            rotate:0,//slide做3d旋转时Y轴的旋转角度。默认50。
            stretch:200,//每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
            depth:1200,//slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
            modifier:1,//depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
            slideShadows:false,//开启slide阴影。默认 true。
        },
    }
    )
  },
}
</script>

<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.main{
    width: 100%;
    height: 100%;
}
.swiper-container{
    width: 100%;
    height: 100%;
}
img{
    margin-left: 80px;
    width: 1000px;
    height: 600px;
}
</style>
