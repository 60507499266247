<template>
  <div class="main">
    <div class="swiper-container">
          <div class="swiper-wrapper">
              <div class="swiper-slide swiper-no-swiping" data-swiper-autoplay="5000"><img src="../assets/header.png" alt="" /></div>
              <div class="swiper-slide swiper-no-swiping" data-swiper-autoplay="5000"><img src="../assets/IgaoTwo.png" alt="" /></div>
          </div>
          <!-- 分页器 -->
          <div class="swiper-pagination"></div>
      </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
export default {
  name: 'swiperHeader',
  props: {
    msg: String
  },
  data() {
    return {
    }
  },
  mounted(){
    new Swiper ('.swiper-container', {
        loop:true,//开启循环
    }
    )
  },
}
</script>

<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.main{
    width: 100%;
    height: 100%;
}
// .swiper-container{
//     width: 100%;
//     height: 100%;
//     .swiper-container{
//         width: 100%;
//         height: 100%;
//     }
// }
// .swiper-slide{
//     width: 100%;
//     height: 100%;
//     img{
//         width: 100%;
//         height: 100%;
//     }
// }
.swiper-container-3d .swiper-wrapper, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-cube-shadow{
    transform-style:initial;
}

.swiper-pagination{
    margin-bottom: 60px;
    .swiper-pagination-bullet{
        width: 100px !important;
    }
}
img{
    width: 100%;
    height: 100%;
}
</style>
