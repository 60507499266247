<template>
  <div class="hello">
    <div id="container" style="width: 100%;height: 480px"></div>
    <!-- <img src="../assets/phones.png"> -->
  </div>
</template>

<script>
import BMap from 'BMap'
export default {
  name: 'baidu',
  props: {
    msg: String
  },
  mounted(){
    var map = new BMap.Map('container')
    // 坐标定位
    var point = new BMap.Point(120.254696,30.210557)
    map.centerAndZoom(point, 17)
    map.enableScrollWheelZoom(true)
    var point = new BMap.Point(120.254696,30.210557);
    // 坐标形状
    var myIcon = new BMap.Icon("https://api.map.baidu.com/img/markers.png", new BMap.Size(23, 25));
    var marker2 = new BMap.Marker(point, {icon: myIcon}); 
    map.addOverlay(marker2);
    // 弹窗
    // var infoWindow = new BMap.InfoWindow(
    //   "<p class='name'>浙江康体汇科技有限公司</p><p class='nameS'>公司</p><p class='name-phone'><img src='./assets/LUi.png' alt=''/>杭州市萧山区建设三路杭州湾信息港D座401</p><p class='name-phone'>0571-86612166</p>", {
    //   boxStyle: {
    //     opacity: "1",
    //     background: "#FFFFFF",
    //     width: "4000px",
    //     height: "150px"
    //   },
    //   closeIconUrl: "lib/close.png",
    //   closeIconMargin: "10px 10px 0 0",
    //   enableAutoPan: true,
    //   align: INFOBOX_AT_TOP
    // });
    // map.openInfoWindow(infoWindow, map.getCenter());  
    //弹出窗口
    // marker2.addEventListener("click", myfunction);
    // map.openInfoWindow(infoBox, map.getCenter()); 
    // function myfunction(){
    //     this.openInfoWindow(infoWindow);
    // }
    // 弹窗内容
    var html = "<div style='padding:16px;'><p class='name'>浙江康体汇科技有限公司</p><p class='nameS'>公司</p><p class='name-phone'><img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAQCAYAAAAiYZ4HAAABx0lEQVQ4T32SPWgUQRTH35vJLXJEuIMUFrEIpIycFgELiwsqxk4hRcoD4cSFmdkrBAtBG9Fy5u6ynWgZENFCkKAhWwgpbw8sLFIIpkgpJNXcsk9enAtrNG4xH2/f7/++BiF8nU7nXLPZvF+W5ToALAXzVyHE5mg02siyrGAb8hLH8YUoij4CwOWpwKk9997fTtP0AFm50WjsBuc9AHjovf/MgJRyVUr5HAAWASDP83wZe72eISILAHve+ytpmh5V1bvd7ly9XmfBRURM0Bizi4hXAeCutfa91vo6Ir5miIg6/X5/Wym1JqV8Q0RfGDhExFnv/XlWN8b8QMT5EOW7tXYhjuPZKIoOiejovwAR7TvnLk4BAPiJSZK8A4A7RLTmnHurlLohhHgVIjxwzn2opLSJWutVIQS39J9FB/VR6NTK8RySJNkBgDZDRPRoMplssb1Wq91CxBfBObPW/ga01ktCCFaZOWNwRVEUl4bD4bdjIER5AgBPzwAeW2ufnTwNPrTb7ZlWq7WDiNeqEPd+PB6v/PGWpg5KqXkpJac2FwZ3UJbl8mAw2J/6nKRUSY2L/xTuN621WTXiXwD/NMbc49059/J0Tb8A3zfFqkBDBd4AAAAASUVORK5CYII=' style='vertical-align:middle' alt=''/><span style='margin-left:8px;'>杭州市萧山区建设三路杭州湾信息港D座401</span></p><p class='name-phone'><img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAABIUlEQVQoU4XRsUvEMBQG8O9rQUQqdC4u4iBO4p/g4Ohm4AaHguhk29t0UKkKJzi1l1EFV1f/Em90OOFcHax4iCLJk8D1UPFqpsD7fsnLC5VSfhRFJyR3RWSnLMsbNCxmWXZK8sBlROTTWrukte5PMg68kgy+BS6LothuAg8k50eBF2ttp9vtnk8EaZrue553BsAaY2a01h+Nb3DFdrv9DCAEsFIUxd2/IEmSdd/3bwEMjDGLTbewPi3LsiOSxyJyb61drtGo5ZYxZk1r/TQGSqmpKIquSG6KyCOAjeFw2A+CYDCaYiUirTFwN+V57lVVtQeg4/6E5DuA2boLEXn7AepCmqarJC9ILvwaQPkncKE4jqfDMNxyWxGZA3Dd6/UOvwASXXaHFmUhcAAAAABJRU5ErkJggg==' style='vertical-align:middle' alt=''/><span style='margin-left:8px;'>0571-86612166</span></p></div>";
    // 弹窗样式
    var infoBox = new BMapLib.InfoBox(map, html, {
      boxStyle: {
        opacity: "1",
        background: "#FFFFFF",
        width: "400px",
        height: "160px"
      },
      closeIconUrl: "/img/close.b5bc3d07.png",
      closeIconMargin: "10px 10px 0 0",
      enableAutoPan: true,
      align: INFOBOX_AT_TOP
    });
    // 点击显示弹窗
    marker2.addEventListener("click", function () {
      infoBox.open(marker2);
    }); 
    // 默认显示弹窗
    infoBox.open(marker2);
  },
  methods:{
    
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.name{
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 800;
  line-height: 25px;
  color: #262626;
  opacity: 1;
}
</style>
